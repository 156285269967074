<template>
  <section>
    <div class="w-100 bg-white shadow-sm rounded-lg p-4 mt-3">
      <div
        v-if="hasValidatedPhone"
        class="validate-message text-danger py-2 px-3 mb-4 d-flex justify-content-center align-items-center"
      >
        <hs-icon variant="light" icon="exclamation-circle" size="20" />
        <span class="font-size-sm ml-2">{{
          $t('my-account.views.account-settings.components.phone-validation.title')
        }}</span>
      </div>
      <hs-form id="phone_validation">
        <hs-group label="Telefone Celular" label-for="school_phone">
          <VueTelInput
            id="school_phone"
            v-model="form.phone"
            type="tel"
            :inputOptions="vueTelInputOptions"
            :defaultCountry="country"
            :preferredCountries="['BR', 'US', 'AO', 'PT', 'AR']"
            :disabledFetchingCountry="true"
            @blur="$v.form.phone.$touch()"
            @country-changed="countryChanged"
            @input="validateNumber"
          />
          <hs-form-invalid-feedback :state="!$v.form.phone.$invalid && isValidPhone">
            <span v-if="!$v.form.phone.required">
              {{ $t(`sign_up.sparkmembers.form.validation.required.phone`) }}
            </span>
            <span v-if="!isValidPhone && country === BrasilDDI && $v.form.phone.required">
              {{ $t(`my-account.views.account-settings.components.phone-validation.form.length`) }}
            </span>
            <span v-if="!isValidPhone && country !== BrasilDDI && $v.form.phone.required">
              {{ $t(`sign_up.sparkmembers.form.fields.phone_text_international`) }}
            </span>
          </hs-form-invalid-feedback>
        </hs-group>
        <div v-b-tooltip.hover.bottom="buttonTooltip">
          <MButton
            class="mt-4 w-100"
            variant="primary-outline"
            size="md"
            :label="buttonLabel"
            :disabled="$v.form.phone.$invalid || !canValidatePhone"
            @click="showValidationModal"
          />
        </div>
      </hs-form>
    </div>
  </section>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';
import { hsForm } from '@/components';
import { required, minLength } from 'vuelidate/lib/validators';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import dayjs from 'dayjs';

export default {
  props: {
    phone: {
      type: String,
    },
    userId: {
      required: true,
      type: String,
    },
    ddi: {
      required: true,
    },
    phoneValidated: {
      required: true,
      type: String,
    },
    canChange: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    VueTelInput,
    MButton,
    hsForm,
  },
  validations: {
    form: {
      phone: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        phone: '',
      },
      vueTelInputOptions: {
        placeholder: '(00) 00000-0000',
      },
      country: null,
      isValidPhone: true,
      BrasilDDI: '55',
    };
  },
  created() {
    this.$root.$on('changePhoneValidation', data => {
      this.form.phone = data.phone;
      this.country = +data.ddi;
    });
    this.form.phone = this.phone;
    this.country = +this.ddi;
  },
  computed: {
    hasValidatedPhone() {
      return !this.phoneValidated;
    },
    buttonLabel() {
      return this.hasValidatedPhone ? 'Validar celular' : 'Alterar';
    },
    buttonTooltip() {
      return this.canValidatePhone ? false : `Altere o número a partir de ${this.whenCanValidate}`;
    },
    canValidatePhone() {
      return this.canChange;
    },
    whenCanValidate() {
      return this.phoneValidated
        ? dayjs(this.phoneValidated)
            .add(3, 'month')
            .format('DD/MM/YYYY')
        : '';
    },
  },
  methods: {
    validateNumber(phoneNumber, phoneObject) {
      this.isValidPhone = phoneObject.valid;
    },
    countryChanged(country) {
      this.country = country.dialCode;
    },
    showValidationModal() {
      this.$emit('showValidationModal', { userId: this.userId, phone: this.form.phone, ddi: this.country });
    },
  },
};
</script>

<style lang="scss" scoped>
.validate-message {
  background: #feb4b4;
  border-radius: 4px;
  span {
    font-weight: 600;
  }
}

.invalid-feedback {
  span {
    font-size: 0.75rem;
    font-weight: 500;
  }
}
</style>
